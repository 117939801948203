.abyss-tabs-content-container {
    padding: var(--abyss-space-xs) var(--abyss-space-md) var(--abyss-space-md) !important;
}
.abyss-drawer-menu-container {
    max-width: 70px !important;
}

.abyss-date-input-range-root {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
